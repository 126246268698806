import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 20px;
  text-align: center;
  max-width: 200px;  
  margin-left: 50%;
  transform: translateX(-50%);
  border-radius: 10%;
`;

export default Wrapper;