module.exports = {
  title: 'Making the most unique cricket manager games', // Required
  author: 'Briashta Games', // Required
  tagline: 'Games for everyone!',
  primaryColor: '#000', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: null,
    github: {
      link: 'https://github.com/ashbhir',
      imgSrc: './src/components/Social/logo-github.svg'
    },
    twitter: {
      link: 'https://twitter.com/ashbhir',
      imgSrc: './src/components/Social/logo-twitter.svg',
    },
    linkedin: {
      link: 'https://www.linkedin.com/in/ashishgambhir2/',
      imgSrc: './src/components/Social/logo-linkedin.svg'
    },
    medium: {
      link: 'https://medium.com/@ashishgambhir',
      imgSrc: './src/components/Social/logo-medium.png'
    }
  }
};
